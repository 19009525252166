<template>
  <div class="user-wrapper">
    <div class="container">
      <div class="user-main">
        <div class="user-info" v-if="$store.state.login">
          <div v-if="$store.state.userInfo.profile_photo_url">
            <img  :src="$store.state.userInfo.profile_photo_url" style="width:80px;height:80px;border-radius: 80px;border:2px solid #FF7B13;" />
          </div>
          <div v-else>
            <img src="@/assets/images/web/user_info/user_girl.png" v-if="$store.state.userInfo.sex==2" style="width:80px;height:80px;border-radius: 80px;border:2px solid #FF7B13;" />
            <img src="@/assets/images/web/user_info/user_boy.png" v-if="$store.state.userInfo.sex==1||$store.state.userInfo.sex==0" style="width:80px;height:80px;border-radius: 80px;border:2px solid #FF7B13;" />
          </div>
          <div>
            <span>{{$store.state.userInfo.name}}  </span>
          </div>
          <div v-if="$store.state.userInfo.vip_auth!=0&&$store.state.userInfo.vip_get!=0">
            <img src="@/assets/images/web/vip-logo.svg" style="width:23px;height:17px;"/>
          </div>
        </div>
        <div class="menu-info">
          <p :selected="menukey==1" @click="onChangeTab(1)">个人资料</p>
          <p :selected="menukey==2" @click="onChangeTab(2)">我的收藏</p>
          <p :selected="menukey==3" @click="onChangeTab(3)">我的会员</p>
          <p :selected="menukey==4" @click="onChangeTab(4)">我的订单</p>
          <p :selected="menukey==5" @click="onChangeTab(5)">发票管理</p>
          <p :selected="menukey==6" @click="onChangeTab(6)">授权认证</p>
          <p :selected="menukey==7" @click="onChangeTab(7)">授权记录</p>
          <p :selected="menukey==8" @click="onChangeTab(8)">优惠券</p>
          <p :selected="menukey==9" @click="onChangeTab(9)">账号安全</p>
        </div>
      </div>
      <div class="cont-main" id="boxTable">
        <component :is="curComponent"></component>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapMutations } from "vuex";
  export default {
    data() {
      return {
        curComponent: '',
        ComponentList:{
          'member1': './user/user.vue',
          'member2': './favorite/favorite.vue',
          'member3': './vip/vip.vue',
          'member4': './order/order.vue',
          'member5': './invoice/invoice.vue',
          'member6': './auth/auth.vue',
          'member7': './authlog/authlog.vue',
          'member8': './coupon/coupon.vue',
          'member9': './user/safe.vue',
        },
      }
    },
    created() {
      this.setDefaultIndex();
      this.isLogin();
    },
    watch: {
      $route() {
        this.setDefaultIndex();
      }
    },
    methods: {
      ...mapMutations(["changeLoginAndRegisterDialog"]),
      isLogin(){
          if(!this.$store.state.login) {
              this.changeLoginAndRegisterDialog(true);
          }
      },
      onChangeTab(newVal) {
        this.$root.$goSearch({
          menukey: newVal
        })
      },
      setDefaultIndex(){
        let menukey = this.$route.query.menukey || '1';
        this.menukey = menukey;
        let p = this.ComponentList['member'+this.menukey];
        this.curComponent = () => import(`${p}`) // 动态组件引入
      },
    }
  }
</script>
<style>
</style>
<style lang="scss" scoped>  
  ::v-deep {
    .m-bottom-lg{
      margin-bottom: -25px !important;
    }
  }
  .user-wrapper {
    width: 100%;
    min-height:300px;
    padding-top: 24px;
    background: #F9FAFA;
    vertical-align:top;
    .user-main{
      display:inline-block;
      width: 20%;
      //position: fixed;
      //top: 88px;
      //z-index: 4;
      .user-info, .menu-info{
        background: #FFFFFF;
      }
      .user-info{
        height: 160px;
        width: 90%;
        margin: 0 auto;
        text-align: center;
        div:nth-child(1){
          width: 100%;
          height:109px;
          padding-top: 29px;
          text-align: center;
        }
        div:nth-child(3){
          width: 40%;
          margin: 0 auto;
          margin-top: -40px;
          text-align:right;
          margin-right: 38%;
        }
      }
      .menu-info{
        height:600px;
        width: 90%;
        margin:0 auto;
        margin-top: 16px;
        padding-top: 19px;
        text-align:center;
        p{
          display: block;
          height: 22px;
          font-size: 16px;
          font-weight: 400;
          color: #333333;
          line-height: 22px;
          padding-bottom: 39px;
        }
        p[selected], p:hover{
          color: #FF7B13;
        }
        p:hover{
          cursor: pointer;
          color: #FF7B13;
        }
      }
    }
    .cont-main {
      display:inline-block;
      vertical-align:top;
      width: 73.9%;
      margin-left: 1%;
      background: #FFFFFF;
      min-height: 777px;
      padding-bottom: 5%;
    }
  }
  @media only screen and (max-width: $screen-width-md) {
    .user-wrapper{
      .user-main{
        .menu-info{
          p{
            font-size: 12px;
          }
        }
      }
    }
  }
</style>
